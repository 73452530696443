


@font-face {
    font-family: "Codec";
    src: url('../../assets/fonts/Codec-Pro-Light.eot');
    src: url('../../assets/fonts/Codec-Pro-Light.woff2') format('woff2'),
         url('../../assets/fonts/Codec-Pro-Light.woff') format('woff'),
         url('../../assets/fonts/Codec-Pro-Light.ttf') format('truetype');
    font-weight: 300; // 
    font-style: normal;
}
@font-face {
    font-family: "Codec";
    src: url('../../assets/fonts/Codec-Pro-Regular.eot');
    src: url('../../assets/fonts/Codec-Pro-Regular.woff2') format('woff2'),
         url('../../assets/fonts/Codec-Pro-Regular.woff') format('woff'),
         url('../../assets/fonts/Codec-Pro-Regular.ttf') format('truetype');
    font-weight: 400;// normal
    font-style: normal;
}

@font-face {
    font-family: "Codec";
    src: url('../../assets/fonts/Codec-Pro-Bold.eot');
    src: url('../../assets/fonts/Codec-Pro-Bold.woff2') format('woff2'),
         url('../../assets/fonts/Codec-Pro-Bold.woff') format('woff'),
         url('../../assets/fonts/Codec-Pro-Bold.ttf') format('truetype');
    font-weight: 700; // Bold
    font-style: normal;
}
@font-face {
    font-family: "Codec";
    src: url('../../assets/fonts/Codec-Pro-Extrabold.eot');
    src: url('../../assets/fonts/Codec-Pro-Extrabold.woff2') format('woff2'),
         url('../../assets/fonts/Codec-Pro-Extrabold.woff') format('woff'),
         url('../../assets/fonts/Codec-Pro-Extrabold.ttf') format('truetype');
    font-weight: 800; // Bold
    font-style: normal;
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight


* {
    font-family: "Codec";
}



@import "bootstrap-5.1.0/bootstrap.scss";
@import "../slick/slick.scss";
@import "../slick/slick-theme.scss";



html {
    background-color: #6F5D00;
}

body {
    overflow-x: hidden;
}

.s-start,
.s-technology,
.s-traitseeker,
.s-customers {
    background-repeat: no-repeat;
    background-size: 1920px auto;
    background-position-x: center;
    background-position-y: top;
}

.s-start {
    background-color: #8A9F63;
    background-image: url('../img/bg-1.jpg');
}

.s-technology {
    background-color: #07608E;
    background-image: url('../../assets/img/bg-2.jpg');
    min-height: 1000px;
}

.s-dm {
    
    background-image: url('../../assets/img/bg-data.jpg');
    min-height: 800px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    background-color: #7B7975;
}

.s-traitseeker {
    background-color: #CFE1F7;
    background-image: url('../../assets/img/bg-ts.jpg'), linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(207,225,247,1) 50%);
    background-position-y: 180px;
}
@include media-breakpoint-up(lg) { 
    .s-traitseeker {
        background-position-y: 130px;
    }
}

@include media-breakpoint-down(md) { 
    .s-traitseeker {
        background-position-y: bottom;
    }
}

.s-gallery {
    background-color: #B7C883;
}

.s-contact {
    background-color: #B7C883;
}

.s-customers {
    background-color: #B7C883;
    background-image: url('../../assets/img/bg-customers-small.jpg');
}

// @include media-breakpoint-down(md) { 
//     .s-customers {
//         background-position-y: bottom;
//     }
// }


.s-footer {
    border-top: 14px solid #413400;
    background-color: #6F5D00;
    color: #B7C883;
}

.text-white {
    color: white;
}


p {
    font-size: 15px;
    line-height: 115%;
}

b {
    font-weight: 700;
}

.p1 {
    font-size: 24px;
    color: #413400;
    font-weight: 400;
}
.p2 {
    font-size: 14px;
    color: #413400;
    font-weight: 400;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}


.t1 {
    font-weight : 700;
    font-size : 36px;
    line-height: 115%;
    color : #335200;
}

.t2 {
    font-size : 21px;
    font-weight: 300;
    line-height: 115%;
    color : #FFFFFF;
    b {
        font-weight: 700;
    }
}


.t3 {
    font-weight: 800;
    font-size : 36px;
    line-height : 26px;
    line-height: 115%;
    color : #FFFFFF;
}

.t3-brown {
    @extend .t3;
    color : #413400;
    border-bottom: 2px solid #413400;
    padding-bottom: .25em;
}
.t3-brown-light {
    @extend .t3;
    color : #413400;
    font-weight: 300;
}

.t4-green {
    font-size : 36px;
    color : #00625F;
    border-top: 2px solid #00625F;
    padding-top: .5em;
}

.t5 {
    font-size : 32px;
    color : #FFFFFF;
    font-weight: 300;
    line-height: 115%;
}

.t6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 115%;
    margin-bottom: 0;
    color: #fff;
}


.u1 {
    margin: 0;
    padding: 0;
    margin-left: 1em;
  li {
    font-family : "Codec";
    font-size : 16px;
    color : #00625F;
    margin: 0;
    padding: 0;
    line-height: 115%;;
  }
}

strong {
    font-weight: 700;
}

.u2 {
    margin: 0;
    padding: 0;
    list-style-type: none;
  li {
    text-align: center;
    font-family : "Codec";
    font-weight: 300;
    font-size : 14px;
    color : #FFFFFF;
    margin: 0;
    padding: 0;
    line-height: 115%;
    margin-bottom: .25em;
  }
}



.b1 {
    padding: 1em;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #335200;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    .b-content {
        z-index: 2;
        position: relative;
        color : #FFFFFF;
    }
}

.b2 {
    padding: 1em;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #879D5F;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    .b-content {
        z-index: 2;
        position: relative;
        color : #FFFFFF;
    }
}

.b3 {
    padding: 1em;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #0059A3;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    .b-content {
        z-index: 2;
        position: relative;
        color : #FFFFFF;
    }
}


.b4 {
    padding: 1em;
    position: relative;
    height: 100%;
    strong {
        line-height: 160%;
    }
    p a {
        color: #FFFFFF!important;
        text-decoration: none!important;
        border-bottom: 1px solid #FFFFFF;
        padding-bottom: 3px;

    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #81795E;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    .b-content {
        z-index: 2;
        position: relative;
        color : #FFFFFF;
    }
}


.b8 {
    padding: 1em;
    position: relative;
    height: 100%;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #3d4247;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    .b-content {
        z-index: 2;
        position: relative;
        color : #FFFFFF;
    }
}


.b5 {
    background-color: #F3F1EB;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    padding: 1rem;
    p {
        font-size : 32px;
        font-weight: 300;
        color : #332500;
        line-height: 115%;
        margin: 0;
        padding: 0;
    }
}

.b6 {
    @extend .b5;
    p {
        font-size : 24px;
    }
}

.b7 {
    background-color: #000000;
    padding: 1rem;
}


.table1 {
    font-size: 13px;
    color: white;
    text-align: center;

    display:block;  
    margin-top:50px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100px;
        background-color: red;
        bottom: 0px;
        left: -1000000px;
    }
    th {
        position: sticky;
        z-index: 2;
        top: 0;
    }
    thead {
        
    }
    td,th {
        border-bottom: 1px solid #022D43;
    }
    tbody {
        display:block;
        overflow-y:scroll; 
        height:200px;
    }
    td, th {
        width:100px;
    }
}





.header-links {
    margin: 0;
    padding: 0;
    color: white;
    list-style-type: none;
    display: block;
    float: left;
    @include media-breakpoint-up(xl) { 
        float: right; 
    }
    li {
        display: block;
        float: left;
        margin-bottom: 0.5em;
        margin-left: 0em; 
        margin-right: 1.5em;
        @include media-breakpoint-up(xl) { 
            margin-left: 1.5em; 
            margin-right: 0em;
        }
    }
    a {
        color: white;
        font-size: 18px;
        text-decoration: none;
        padding-bottom: 1.5px;
        border-bottom: 1.5px solid white;
        text-shadow: 0px 0px 16px rgba(0,0,0,0.30);
    }
}


.logo-ct,
.logo-ts {
    // max-height: 90px;
    max-width: 80%;
}

@include media-breakpoint-up(md) { 
    .logo-ct,
    .logo-ts {
        max-height: 140px
    } 
}



footer {
    .logo {
        max-width: 70%;
    }
}


.button-1 {
    font-size: 21px;
    font-weight: 700;
    color: #335200;
    text-decoration: none;
    border: 1px solid #335200;
    padding: .75em;
    display: inline-block;
    &:hover {
        color: #335200;
    }
}


.video-image {
    position: relative;
    z-index: 1;
    &:before {
        content: " ";
        position: absolute;
        width: 100px;
        height: 100px;
        left: 50%;
        top: 50%;
        margin-left: -50px;
        margin-top: -50px;
        border: 4px solid rgba(255,255,255,0.8);
        border-radius: 50px;
        z-index: 1;
        box-shadow: 0 5px 31px rgba(0, 0, 0, 0.8), 0 5px 30px rgba(0, 0, 0, 0.8) inset  ; 
    }
    &:after {
        content: " ";
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -17px;
        margin-top: -30px;
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 50px solid rgba(255,255,255,0.8);
        z-index: 2;
    }
}
.video-image:hover {
    cursor: pointer;
    &:before {
        border: 4px solid rgba(255,255,255,1);
    }
    &:after {
        border-left: 50px solid rgba(255,255,255,1);
    }
}


.slick-dots li {
    margin: 0 0;
}
.slick-dots li,
.slick-dots li button {
    width: 20px;
    height: 25px;
    padding: 0;
}
.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 7px;
    height: 7px;
    color: white;
    background-color: #FFFFFF;
    border-radius: 100%;
    opacity: .3;
    left:7.5px;
    top: 7px;
}
.slick-dots li.slick-active button:before {
    color: white;
    opacity: 1;
}
.slick-slide {
    height: auto;
}
.slick-prev,
.slick-next {
    z-index: 10;
    height: 100%;
    width: 45px;
}
.slick-prev {
    left: -40px;
}
.slick-next {
    right: -40px;
}
.slick-prev:before, .slick-next:before {
    opacity: 0.3;
}



.slick-gallery-full {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    .slick-prev, 
    .slick-next {
        width: 60px;
        height: 100%;
        &:before {
            width: 40px;
            height: 40px;
            font-size: 40px;
            opacity: .7;
        }
    }
    .slick-prev:hover, 
    .slick-next:hover {
        &:before {
            opacity: 1;
        }
    }
    .slick-prev {
        left: 40px;
        z-index: 10;
    }
    .slick-next {
        right: 40px;
        z-index: 10;
    }
    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
    .slick-dots {
        bottom: 50px;
        li, button {
            width: 30px;
            height: 30px;
            button:before {
                width: 18px;
                height: 18px;
                opacity: 1;
                background-color: rgba(255, 255, 255, 0.1);
                border: 2px solid white;
                &:hover {
                    background-color: rgba(255, 255, 255, 1);
                }
            }
        }
        li.slick-active {
            button:before {
                background-color: rgba(255, 255, 255, 1);
            }
        }
    }

    width: 100%;
    .gallery-img {
        // width: 100%;
        min-height: 800px;
        background-size: 10%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // background-image: url('../../assets/img/gallery/01.jpg') contain;
    }
}




// inputs

.form-control {
    border-top: 0px solid #ced4da;
    border-left: 0px solid #ced4da;
    border-right: 0px solid #ced4da;
    border-bottom: 1px solid #413400;
}

.col-form-label {
    color: 413400;
}
